function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1><code>Computational</code> Graphic&nbsp;Design and Typography Practices</h1>
      </header>
    </div>
  );
}

export default App;
